//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  components: {
    XModal: () => import("@/components/XModal"),
    ThermodynamicChart: () => import("@/components/ThermodynamicChart"),
  },
  props: {
    //是否显示截图
    IsRealTimeScreenshot: {
      type: Boolean,
      default: true,
    },
    selTask: {
      type: Object,
      default: null,
    },
    date: {
      type: String,
      default: null,
    },
    teamValue: {
      type: Number,
      default: null,
    },
    selMem: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      ds: null,
      de: null,
    };
  },
  computed: {
    cmurl() {
      return process.env.VUE_APP_CMURL;
    },
  },
  methods: {
    opened() {
      this.$nextTick(() => {
        this.ds = new Date(this.date).timeFormat(
          `yyyy-MM-dd ${this.selTask.StartTime}`
        );
        if (this.selTask.EndTime) {
          this.de = new Date(this.date).timeFormat(
            `yyyy-MM-dd ${this.selTask.EndTime}`
          );
        } else {
          this.de = new Date().timeFormat(`yyyy-MM-dd HH:mm`);
        }
      });
    },
    closed() {
      this.ds = null;
      this.de = null;
    },
  },
};
